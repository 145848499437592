require("dotenv").config({
   path: `.env.${process.env.NODE_ENV}`,
});

// 1. Projects Variables
const PROJECT_NAME = process.env.SETTINGS_NAME || "site_name";
const PROJECT_COLOR = process.env.SETTINGS_COLOR || "#000";
const FONTS = process.env.SETTINGS_FONTS ? process.env.SETTINGS_FONTS.split(", ") : false;
const SENTRYDSN = process.env.SETTINGS_SENTRY || false;
const STORYBLOK_KEY = process.env.GATSBY_STORYBLOK_TOKEN_APP || process.env.STORYBLOK_TOKEN_APP;

console.log("Storyblok key : ", STORYBLOK_KEY);

// 2. Ajout des plugins facultatifs
let plugins = [];

// 2.1 Add Google Fonts
if (FONTS) {
   plugins.push({
      resolve: `gatsby-plugin-google-fonts`,
      options: {
         fonts: FONTS,
         display: "swap",
      },
   });
}

// 2.2 Add Sentry
if (SENTRYDSN && SENTRYDSN !== "false") {
   plugins.push({
      resolve: "@sentry/gatsby",
      options: {
         dsn: SENTRYDSN,
         sampleRate: 0.7,
      },
   });
}

module.exports = {
   siteMetadata: {
      siteUrl: process.env.SETTINGS_SITE_URL || "http://localhost:8000",
      title: PROJECT_NAME,
   },
   plugins: [
      {
         resolve: "gatsby-source-storyblok",
         options: {
            accessToken: STORYBLOK_KEY,
            homeSlug: "/",
            version: process.env.NODE_ENV === "production" ? "published" : "draft",
            resolveRelations: ["category", "page", "block", "pages", "stories", "page.block", "reusable_block.block", "link", "links"],
            resolveLinks: "story",
         },
      },
      {
         resolve: `gatsby-source-filesystem`,
         options: {
            name: `images`,
            path: `${__dirname}/src/images`,
         },
      },
      {
         resolve: "gatsby-plugin-robots-txt",
         options: {
            host: process.env.SETTINGS_SITE_URL || "http://localhost:8000",
            policy: [{ userAgent: "*", disallow: "/" }],
         },
      },
      "gatsby-plugin-react-helmet",
      "gatsby-transformer-sharp",
      "gatsby-plugin-postcss",
      ...plugins,
   ],
};
